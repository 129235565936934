<template>
  <div>
    <div class="card-toolbar mb-5">
        <router-link v-if="$can('taxes.create')" to="/settings/taxes/create" class="btn btn-primary font-weight-bolder">
            <v-icon>mdi-plus</v-icon>{{ $t('tax.add_tax')}}
        </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="name">{{$t('name')}}</label>
                        <input v-model="filters.name" type="text" id="name" class="form-control">
                    </div>
                    
                    <!-- <div class="form-group col-md-6">
                        <label for="phone">{{$t('phone')}}</label>
                        <input v-model="filters.phone" type="text" id="phone" class="form-control">
                    </div> -->
                    
                    <div class="form-group d-inline-flex col-md-6">
                        <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                        </button>
                        <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
          <div class="d-flex justify-content-end mb-5">
              <custom-export-data v-if="$can('taxes.export_data')" :data-list.sync="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
          </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

            <template slot="status" slot-scope="props">
                <b-form-checkbox  v-if="$can('taxes.change_status')"
                    size="lg" @change="changeStatus(props.row.id, props.row.is_active)" 
                    v-model="props.row.is_active" 
                    :name="'check-button'+props.row.id" 
                    switch :key="props.row.id">
                </b-form-checkbox>
                <b-form-checkbox  v-else
                    size="lg" :disabled="true"
                    v-model="props.row.is_active"
                    :name="'check-button'+props.row.id"
                    switch :key="props.row.id">
                </b-form-checkbox>
            </template>
            
            <template slot="actions" slot-scope="props">
              <v-btn v-if="$can('taxes.update')" icon color="pink" :to="`taxes/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
              </v-btn>
                <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('taxes.delete') && props.row.id != 1" @click="deleteTax(props.row)">mdi-delete</v-icon>
              <button @click="resetDefaultValues(props.row.id)" class="btn btn-secondary btn-sm m-btn m-btn--icon w-auto">
                {{$t('reset_default_values')}}
              </button>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";

    export default {
        name: "tax-index",
        components: {},
        data() {
            return {
                showAdvancedSearch: false,
                mainRoute:'settings/taxes',
                routeChangeStatus : 'settings/tax/change-status',
              mainRouteResetDefaultValues : 'settings/tax/reset_tax',
                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: '',
                    status: '',
                },
                columns: ['id','tax_name', 'value_rate', 'include_tax', 'type_name', 'description', 'status','created_at', 'actions'],
                data: [],
                dataList : [],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('tax.tax_name')] = 'tax_name';
                fields[this.$t('tax.value_rate')] = 'value_rate';
                fields[this.$t('tax.include_tax')] = 'include_tax';
                fields[this.$t('tax.type')] = 'type_name';
                fields[this.$t('tax.description')] = 'description';
                fields[this.$t('created_at')] = 'created_at';
                fields[this.$t('status')] = 'is_active_label';
                return fields;
            },
            fileName : function () {
                return this.$t('tax.taxes');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        id: that.$t('#'),
                        tax_name: that.$t('tax.tax_name'),
                        value_rate: that.$t('tax.value_rate'),
                        include_tax: that.$t('tax.include_tax'),
                        type_name: that.$t('tax.type'),
                        description: that.$t('tax.description'),
                        status: that.$t('status'),
                        created_at: that.$t('tax.created_at'),
                        actions: that.$t('global.actions'),
                    },
                    sortable: ['tax_name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        tax_name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.tax_Management")}]);
        },
        methods: {
        changeStatus(id, status) {
            ApiService.patch(this.routeChangeStatus + '/' + id, {
                is_active: (status ? 1 : 0),
            }).then((response) => {
                this.$refs.table.refresh();
                this.$successAlert(response.data.message);
            }).catch((error) => {
                this.$errorAlert(error);
            });
        },
        
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.status = '';

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            actionDelete(item){
                ApiService.delete(`${this.mainRoute}/${item.id}`).then(response => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                });
            },
            deleteTax(unit) {
                this.$confirmAlert('', this.actionDelete, unit);
            },

          resetDefaultValues(id){
            this.$confirmAlert(this.$t('reset_default_values_alert_message'), this.actionResetDefaultValues, id);
          },
          actionResetDefaultValues(id){
            ApiService.patch(`${this.mainRouteResetDefaultValues}/${id}`).then(response => {
              this.getFetch();
              this.$successAlert(response.data.message)
            }).catch((error) => {
              this.$errorAlert(error);
            });
          },

        },
    };
</script>
